import React, { Component } from 'react';

import config from '../../Config';

import Api from '../../Services/Api';
import qs from 'query-string';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import SubHeader from '../../Components/SubHeader';

import $ from 'jquery';

import { getShift } from '../../Util/Functions';

import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'

import './index.css'
import VMasker from 'vanilla-masker';

export default class Scholarships extends Component {

    constructor(props) {
        super(props)
        this.state = {
            scholarships: [],
            alreadyListed: [],
            loading: true,
            filterType: null,
            filterData: null,
            finish: false,
            searchQuery: null,
            filterInstitutionName: '',
            filterCourseName: null,
            filterCityName: '',
            courses: [],
            institutions: [],
            loadingScholarships: false
        }
    }

    componentDidMount() {
        this.getFirstData();
        this.startSelects();
    
        // const { institution } = this.props.match.params;
    
        // // Se houver um parâmetro institution na URL, buscar os cursos dessa instituição
        // if (institution && institution !== 'todos') {
        //     this.getCoursesByInstitution(institution);
        // }
    }
    
    getFirstData = async () => {
        this.setState({ loading: true });
    
        // Extração dos parâmetros da URL usando match.params
        const { category, city, course, institution, type } = this.props.match.params;
    
        // Montar o query a partir dos dados do estado e dos parâmetros da URL
        let query = {
            category: category !== 'todos' ? category : undefined,
            city: city !== 'todos' ? city : undefined,
            course: course !== 'todos' ? course : undefined,
            institution: institution !== 'todos' ? institution : undefined,
            type: type !== 'todos' ? type : undefined,
            ...this.state.filterData,
            alreadyListed: this.state.alreadyListed
        };
    
        // Fazer a requisição para a lista de bolsas
        let scholarshipsRequest = await Api.get('/scholarship/list/order', { params: query });

        let scholarships = scholarshipsRequest.data.scholarships;
    
        // Atualizar a lista de bolsas já listadas
        let alreadyListed = [...this.state.alreadyListed];
        scholarships.forEach(scholarship => {
            alreadyListed.push(scholarship.name);
        });
    
        let courses = [];
        let institutions = [];
    
        // Se o filtro for por instituição, buscar cursos dessa instituição
        if (query.institution) {
            let request = await Api.get(`/course/institution/${query.institution}`);
            courses = request.data.courses;
    
            // Buscar todas as instituições
            let institutionsRequest = await Api.get('/institution');
            institutions = institutionsRequest.data.institutions;
        } else {
            // Caso contrário, buscar todos os cursos e instituições
            let coursesRequest = await Api.get('/course');
            courses = coursesRequest.data.courses;
    
            let institutionsRequest = await Api.get('/institution');
            institutions = institutionsRequest.data.institutions;
        }
    
        // Atualizar o estado com os novos dados
        this.setState({ 
            loading: false,
            courses, 
            institutions, 
            scholarships: [...this.state.scholarships, ...scholarships], 
            finish: scholarships.length < 3, 
            searchQuery: this.props.match.params
        }, () => { 
            this.startSelects(true); 
        });
    }
    

    startSelects = (firstCall = false) => {
        var that = this;
        
        // Configurações de idioma do select2
        $.fn.select2.defaults.set("language", {
            noResults: function () { return "Sem resultados" },
            noMatches: function () { return "Nenhum resultado encontrado"; },
            inputTooShort: function (input, min) { return 'Digite no mínimo 2 caracteres.' },
            loadMore: function (pageNumber) { return "Buscando..."; },
            searching: function () { return "Buscando..."; }
        });
    
        // Opções de select2 para curso e instituição
        let courseSelectOptions = {
            placeholder: "Curso / Série",
            theme: "bootstrap4",
            allowClear: true,
        }
    
        let institutionsSelectOptions = {
            placeholder: "Instituição",
            theme: "bootstrap4",
            allowClear: true,
        }
    
        let institutionSelect = $('.institution-select').select2(institutionsSelectOptions);
    
        $('.course-select').select2(courseSelectOptions);
    
        // Configuração do select2 para cidades com busca via AJAX
        $('.city-select').select2({
            placeholder: "Cidade",
            minimumInputLength: 2,
            theme: "bootstrap4",
            allowClear: true,
            ajax: {
                url: config.API_BASE_URL + '/scholarship/search/city',
                data: function (term, page) {
                    return term
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.cities, function (city) {
                            return {
                                text: city.name,
                                id: city.name
                            }
                        }),
                    };
                }
            }
        });
    
        // Se for a primeira chamada, atualizar os selects com base nos parâmetros da URL
        if (firstCall) {
            const { course, institution } = this.props.match.params;
            
            if (course && course !== 'todos') {
                $('.course-select').val(course.toUpperCase()).change();
            } else {
                $('.course-select').val(null).change();
            }
    
            if (institution && institution !== 'todos') {
                $('.institution-select').val(institution.toUpperCase()).change();
            } else {
                $('.institution-select').val(null).change();
            }
        }
    
        // Limpar eventos anteriores e adicionar eventos novos para o select de instituição
        institutionSelect.off("select2:select");
        institutionSelect.off("select2:unselect");
        
        institutionSelect.on("select2:select", function (e) {
            that.setState({ courses: [] }, () => {
                that.getCoursesByInstitution(e.target.value);
            });
        });
        
        institutionSelect.on("select2:unselect", function (e) {
            that.setState({ 
                searchQuery: { ...that.state.searchQuery, institution: null }, 
                courses: [] 
            }, () => {
                that.getCourses();
            });
        });
    }
    

    parseMoney = (value = 0) => {
        return VMasker.toMoney((parseFloat(value) || 0).toFixed(2))
    }

    getData = async (ignoreSearchQuery = false) => {
        const { category, city, course, type } = this.props.match.params;
    
        // Construir o objeto de query com base nos parâmetros da URL e no estado local
        let query = {
            category: ignoreSearchQuery ? 'todos' : (category || 'todos'),
            city: city || 'todos',
            course: course || 'todos',
            type: type || 'todos',
            ...this.state.filterData,
            alreadyListed: this.state.alreadyListed
        };
    
        try {
            // Fazer a requisição com os parâmetros montados
            let scholarshipsRequest = await Api.get('/scholarship/list/random', { params: query });
            let scholarships = scholarshipsRequest.data.scholarships;
    
            // Atualizar a lista de já listados
            let alreadyListed = this.state.alreadyListed;
            scholarships.forEach(scholarship => {
                alreadyListed.push(scholarship.id);
            });
    
            // Atualizar o estado com as novas bolsas e sinalizar fim de carregamento
            this.setState({ 
                scholarships: [...this.state.scholarships, ...scholarships], 
                loading: false, 
                finish: scholarships.length < 3, 
                searchQuery: { category, city, course, type }
            });
        } catch (error) {
            console.error("Erro ao buscar bolsas:", error);
            this.setState({ loading: false });
        }
    }
    

    getInstitutionName = async (id) => {
        let request = await Api.get('/institution/id/' + id);
        let institution = request.data.institution;
        this.setState({ filterInstitutionName: institution.name })
    }

    getCoursesByInstitution = async (institutionId) => {
        let request = await Api.get('/course/institution/' + institutionId);
        let courses = request.data.courses;
        this.setState({ courses: courses }, () => { this.startSelects() })
        $('.course-select').val(null).trigger('change');
    }

    getCourses = async () => {
        let request = await Api.get('/course');
        let courses = request.data.courses;
        this.setState({ courses: courses }, () => { this.startSelects() })
        $('.course-select').val(null).trigger('change');
    }

    handleFilterSubmit = (e) => {
        e.preventDefault();
        this.setState({ alreadyListed: [], scholarships: [], loading: true });
        
        let formData = new FormData(e.target);
        const { category } = this.props.match.params;
        var queryData = {};
        formData.forEach((value, key) => { queryData[key] = value });
    
        this.setState({ filterData: queryData }, () => {
            // Extração dos dados de filtro, garantindo 'todos' caso estejam indefinidos

            const city = queryData.city || 'todos';
            const course = queryData.course || 'todos';
            const institution = queryData.institution || 'todos';
            const type = queryData.type || 'todos';  // O tipo agora será "todos", "presencial", etc.
    
            // Construir a URL de acordo com o filtro selecionado
            let newUrl = `/bolsas/${category}/${city}/${course}/${institution}/${type}`;
    
            // Redirecionar para a nova URL
            window.location.href = newUrl.toLowerCase();
        });
    }
            

    getEconomy = (fullPrice, finalPrice, duration, durationType) => {
        try {
            let months = 1;
            if (durationType === 'days') months = duration / 30;
            else if (durationType === 'months') months = duration;
            else if (durationType === 'years') months = duration * 12;
            let fullPriceTotal = fullPrice * months;
            let finalPriceTotal = finalPrice * months;
            let economy = fullPriceTotal - finalPriceTotal;
            return economy;
        } catch (error) {
            return 0;
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <SubHeader title="Bolsas" />
                <div className="row mb-4 mx-xxl-5 px-xxl-5">
                    <div className="col-md-3 p-4">
                        <form onSubmit={this.handleFilterSubmit} className="form row border p-1 mx-sm-2" id="form-filter">
                            <span className="text-center mb-3">Selecione pelo menos um filtro e clique em buscar para filtrar os resultados</span>
                            <div className="form-group col-12">
                                <select className="form-control institution-select" name="institution">
                                    {
                                        this.state.institutions.map(institution => (
                                            <option value={institution.name} key={institution.id}>{institution.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group col-12">
                                <select className="form-control course-select" name="course" >
                                    {
                                        this.state.searchQuery && this.state.searchQuery.course && this.state.filterCourseName !== null && this.state.courses.length === 0
                                            ?
                                            <option value={this.state.searchQuery.course} key={this.state.searchQuery.course}>{this.state.filterCourseName}</option>
                                            :
                                            null
                                    }
                                    {
                                        this.state.courses.map(course => (
                                            <option value={course.name} key={course.id}>{course.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group col-12">
                                <select className="form-control city-select" name="city" >
                                    {this.state.searchQuery && this.state.searchQuery.city
                                        ?
                                        <option value={this.state.searchQuery.city} key={this.state.searchQuery.city}>{this.state.searchQuery.city}</option>
                                        :
                                        null
                                    }

                                </select>
                            </div>
                            <div className="form-group px-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="type" id="todos" value="todos" defaultChecked={!this.state.filterType} />
                                    <label className="form-check-label" htmlFor="todos">Todos</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="type" id="checkPresential" value="presencial" defaultChecked={this.state.filterType === 'presencial'} />
                                    <label className="form-check-label" htmlFor="checkPresential">Presencial</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="type" id="semipresencial" value="semi-presencial" defaultChecked={this.state.filterType === 'semi-presencial'} />
                                    <label className="form-check-label" htmlFor="semipresencial">Semipresencial</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="type" id="ead" value="ead" defaultChecked={this.state.filterType === 'ead'} />
                                    <label className="form-check-label" htmlFor="ead">EAD</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-secondary btn-block text-white">ENVIAR</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-9 pr-md-5">
                        {this.state.loading ? 
                            <div className="d-flex h-100 justify-content-center align-items-center" >
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            this.state.scholarships.length > 0 ?
                            this.state.scholarships.map(scholarship => {
                                if (scholarship.quantity_offered - parseInt(scholarship.payments_count) <= 0) return null;
                                return (
                                    <div className="row border scholarship-item mt-4 mx-1 mx-md-0" key={scholarship.id}>
                                        <div className="col-md-2 image-container d-flex align-items-center justify-content-center">
                                            <img width="100%" height="auto" src={scholarship.institution.image.includes("http") ? scholarship.institution.image : require('../../Images/Imagens/' + scholarship.institution.image).default} className="img-fluid institution-image" alt="scholarship_img" />
                                        </div>
                                        <div className="col-md-10 mt-3 mt-md-0">
                                            <div className="row d-flex h-100">
                                                <div className="col-12">
                                                    <span className="d-block scholarship-title">{scholarship.institution.name}</span>
                                                </div>
                                                <div className="col-lg-6 d-flex flex-column justify-content-end order-1">
                                                <table className="mb-1">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className="font-weight-bold">Série: </span>
                                                            </td>
                                                            <td className="pl-2">
                                                                <span>{scholarship.course.name}</span>
                                                            </td>
                                                        </tr>
                                                        {scholarship.period && (
                                                            <tr>
                                                                <td>
                                                                    <span className="font-weight-bold">Período: </span>
                                                                </td>
                                                                <td className="pl-2">
                                                                   {JSON.parse(scholarship.period).map((period, index) => (
                                                                        <span key={index}>{period.value}{index < JSON.parse(scholarship.period).length - 1 ? ', ' : ''}</span>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>
                                                                <span className="font-weight-bold">Bairro: </span>
                                                            </td>
                                                            <td className="pl-2">
                                                                <span>{scholarship.institution.district}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <span className="font-weight-bold">Turno: </span>
                                                            </td>
                                                            <td className="pl-2">
                                                                <span>{getShift(scholarship.shift).toUpperCase()}</span>
                                                                <span className="ml-4 font-weight-bold">Local: </span><span>{scholarship.institution.city}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                <div className="col-12 mt-2 d-inline d-lg-none order-2">
                                                    <div className="hr my-1"></div>
                                                </div>
                                                <div className="col-lg-6 d-flex justify-content-end my-3 my-lg-0 order-5 order-lg-3">
                                                    <div className="row no-gutters text-right d-flex align-items-center">
                                                        <div className="col-9 d-flex flex-column align-items-end justify-content-end">
                                                            <a href={"/matricula/" + scholarship.id} className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center">
                                                                <i className="ti-shopping-cart cart-icon"></i><span className="ml-2 mt-1 font-weight-bold">Quero esta bolsa</span>
                                                            </a>
                                                            <a href={`/detalhes?bolsa=${scholarship.id}`} className="mt-1 p-1 btn btn-secondary d-flex flex-row align-items-center justify-content-center know-more-btn"><i className="ti-plus text-white font-weight-bold"></i><span className="ml-2 mt-1 text-white font-weight-bold">Saiba mais</span></a>
                                                        </div>
                                                        <div className="col-3 pl-1 h-100">
                                                            <div className="bg-primary w-auto h-100 d-flex flex-column justify-content-center align-items-center p-1 px-2 discount-container">
                                                                <span className="d-block text-white">Bolsa:</span>
                                                                <h5 className="font-weight-bold text-white">{scholarship.discount}%</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-0 mt-lg-3 order-4">
                                                    <div className="hr my-1"></div>
                                                </div>
                                                {console.log(scholarship)}
                                                <div className="col-12 mb-0 mb-lg-1 order-3 order-lg-5">
                                                    <div className="row d-flex justify-content-between">
                                                        <div className="col-6 col-lg-auto">
                                                            <span className="d-block text-small text-grey">MODALIDADE</span>
                                                            <span className="text-small text-grey">{scholarship.type.toUpperCase()}</span>
                                                        </div>
                                                        <div className="col-6 col-lg-auto">
                                                            <span className="d-block text-small text-grey">VALOR DA MENSALIDADE</span>
                                                            <strike className="full_price text-extra-small text-grey mr-1">R${this.parseMoney(scholarship.full_price)}</strike>
                                                            <span className="scholarship-final-price-small">
                                                                R$</span><h5 className="d-inline">{this.parseMoney(scholarship.final_price).slice(0, -3)}</h5><span className="font-weight-bold scholarship-final-price-small">,{this.parseMoney(scholarship.final_price).slice(-2)}</span><span className="text-secondary">*</span>
                                                        </div>
                                                        <div className="col-6 col-lg-auto mt-2 mt-md-0 scholasrhip-discount-container">
                                                            <span className="d-block text-small text-grey">BOLSA</span>
                                                            <h5 className="">{scholarship.discount}%</h5>
                                                        </div>
                                                        <div className="col-6 col-lg-auto mt-2 mt-md-0">
                                                            <span className="d-block text-small text-grey">ECONOMIA DE</span>
                                                            <span className="scholarship-final-price-small">
                                                                R$</span><h5 className="d-inline">{this.parseMoney(this.getEconomy(scholarship.full_price, scholarship.final_price, scholarship.course.duration, scholarship.course.duration_type)).slice(0, -3)}</h5><span className="font-weight-bold scholarship-final-price-small">,{this.parseMoney(this.getEconomy(scholarship.full_price, scholarship.final_price, scholarship.course.duration, scholarship.course.duration_type)).slice(-2)}</span><span className="text-secondary">*</span>
                                                        </div>
                                                        <div className="col-12 col-lg-auto d-flex align-items-center justify-content-center">
                                                            {scholarship.quantity_offered - parseInt(scholarship.payments_count) <= 5
                                                                ?
                                                                <span className="text-danger"> {scholarship.quantity_offered - parseInt(scholarship.payments_count)} bolsas restantes</span>
                                                                :
                                                                <span className="text-danger">Ultimas bolsas</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className="d-flex h-100 justify-content-center align-items-center" >
                                <span className="text-center">Nenhuma bolsa encontrada correspondente a pesquisa</span>
                            </div>
                        }
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
