import React, { Component } from 'react';
import qs from 'query-string';
import Api from '../../Services/Api';
import config from '../../Config';
import { parseMoney, getShift } from '../../Util/Functions';

import './index.css';
import moment from 'moment';

export default class Voucher extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: null,
            voucher: null
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        let query = qs.parse(this.props.location.search);
        let voucherRequest = await Api.get('/order/voucher?scholarship_id=' + query.bolsa);
        let userRequest = await Api.get('/user/details/profile');
        console.log(voucherRequest.data)
        this.setState({
            user: userRequest.data.user,
            voucher: voucherRequest.data.voucher
        });
    }

    print = () => {
        return window.print();
    }


    render() {
        if (!this.state.user) return (<div>Carregando</div>)
        return (
            <div className="container-fluid root-container">
                <button className="no-print" onClick={this.print}>Imprimir</button>
                <div className="row content-container p-4 m-1 m-md-5 print-page" id="voucher-print-page">
                    <div className="col-md-4 mb-3 mb-md-0 d-flex align-items-center justify-content-center">
                        <img src={require('../../Images/brasil-logo.webp').default} width="200" height="91" alt="logo" />
                    </div>
                    <div className="col-md-8 mb-3 mb-md-0">
                        <span className="d-block">Gerado em: {moment(this.state.voucher.payments[0].createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                        <span className="d-block">Pago em: {moment(this.state.voucher.payments[0].date_paid).format('DD/MM/YYYY HH:mm:ss')}</span>
                        <span className="d-block">Última atalização da mensalidade em: {moment(this.state.voucher.payments[this.state.voucher.payments.length - 1].date_paid).format('DD/MM/YYYY')}</span>
                        <h4>Cupom de aprovação nº {this.state.voucher.code}</h4>
                    </div>
                    <div className="col-12 bg-secondary text-center my-3 p-2 rounded">
                        <strong>ESTE CUPOM DEVERÁ SER ENTREGUE NO ATO DA MATRÍCULA</strong>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-8">
                                {this.state.user.has_dependent === true
                                    ?
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="d-block">Nome do responsável</span>
                                            <strong className="d-block">{this.state.user.name}</strong>
                                        </div>
                                        <div className="col-6">
                                            <span className="d-block">CPF</span>
                                            <strong className="d-block">{this.state.user.cpf}</strong>
                                        </div>
                                        <div className="col-6">
                                            <span className="d-block">Nome do aluno</span>
                                            <strong className="d-block">{this.state.user.minor_name}</strong>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="d-block">Nome do aluno</span>
                                            <strong className="d-block">{this.state.user.name}</strong>
                                        </div>
                                        <div className="col-6">
                                            <span className="d-block">CPF</span>
                                            <strong className="d-block">{this.state.user.cpf}</strong>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 rounded p-2">
                                <div className="d-flex bg-info h-100 rounded align-items-center justify-content-center p-3 text-center">
                                    <strong className="text-white">
                                        Desconto de {this.state.voucher.payments[0].discount}% nas mensalidades
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Scholarship review */}
                    <div className="col-12 border mt-2 p-2">
                        <div className="row">
                            <div className="col-4 d-flex align-items-center justify-content-center">
                                <img
                                    src={this.state.voucher.scholarship.institution.image.includes("http") ? this.state.voucher.scholarship.institution.image : require('../../Images/Imagens/' + this.state.voucher.scholarship.institution.image).default}
                                    alt="course_image"
                                    className="img-fluid"
                                    width={150}
                                    height={150}
                                />
                            </div>
                            <div className="col-8">
                                <h4>{this.state.voucher.scholarship.institution.name}</h4>
                                <span className="d-block">{this.state.voucher.scholarship.institution.street}, Nº {this.state.voucher.scholarship.institution.number}, {this.state.voucher.scholarship.institution.district}</span>
                                <span className="d-block">{this.state.voucher.scholarship.institution.city} - {this.state.voucher.scholarship.institution.state}</span>
                                <span className="d-block">{this.state.voucher.scholarship.institution.postal_code}</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-md-6 p-3">
                                <span className="d-block">Etapada da formação: <b>{this.state.voucher.scholarship.course.name}</b> </span>
                                <span className="d-block">Turno: <b>{getShift(this.state.voucher.scholarship.shift)}</b> </span>
                                <span className="d-block">Modalidade: <b>{this.state.voucher.scholarship.type}</b> </span>
                                
                                {this.state.voucher.scholarship.period !== null && <span className="d-block">Período: <b>{JSON.parse(this.state.voucher.scholarship.period)[0].value}</b> </span>}
                            </div>
                            <div className="col-md-6 p-3">
                                <span className="d-block">Vantagens inclusas:</span>
                                <span className="d-block"><b>Bolsa de {this.state.voucher.payments[0].discount}%</b></span>
                                <span className="d-block">Valor integral: {parseMoney(this.state.voucher.payments[0].full_price)}</span>
                                <span className="d-block">Valor com desconto: <b>{parseMoney(this.state.voucher.payments[0].final_price)}</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center my-3">
                        <h3>PRÓXIMOS PASSOS</h3>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 flex-column d-flex align-items-center">
                                <div className="bg-primary number-item d-flex text-center align-items-center justify-content-center">
                                    <h4 className="text-white mt-1">1</h4>
                                </div>
                                <div className="text-center">
                                    <strong className="step-title d-block">Inscreva-se e Realize o Pagamento da Taxa de Inscrição</strong>
                                    <span>Após a inscrição, realize o pagamento da Taxa de Inscrição, através de Boleto ou Cartão de Crédito</span>
                                </div>
                            </div>
                            <div className="col-md-4 flex-column d-flex align-items-center">
                                <div className="bg-primary number-item d-flex text-center align-items-center justify-content-center">
                                    <h4 className="text-white mt-1">2</h4>
                                </div>
                                <div className="text-center">
                                    <strong className="step-title d-block">Emissão dos Documentos</strong>
                                    <span>Acesse a área Portal do Aluno e realize a Impressão do Cupom de Aprovação, Comprovante de Pagamento e Contrato do Aluno</span>
                                </div>
                            </div>
                            <div className="col-md-4 flex-column d-flex align-items-center">
                                <div className="bg-secondary number-item d-flex text-center align-items-center justify-content-center">
                                    <h4 className="text-primary mt-1">3</h4>
                                </div>
                                <div className="text-center">
                                    <strong className="step-title d-block">Realize sua matrícula na instituição de ensino</strong>
                                    <span>Compareça até a Instituição de Ensino, e apresente este Cupom de Aprovação.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 bg-primary spacer" />
                    <div className="col-12 mt-3">
                        <h4>Informações importantes:</h4>
                        <strong className="text-dark d-block">CONDIÇÕES:</strong>
                        <span>
                            O cadastro ainda estará sujeito a atender os requisitos previstos no regulamento do programa
                            BRASIL MAIS BOLSAS, incluindo mais não se limitando a: (I) Ter sido aprovado no processo seletivo
                            da Instituição (sempre que exigido), (II) Que o ALUNO BENEFICIADO, não estuda na instituição
                            ou não tem vínculo com a mesma a pelo menos um ano. Desconto é exclusivo para BOLSISTAS do
                            programa BRASIL MAIS BOLSAS e não é cumulativo com outros benefícios Caso o valor da mensalidade
                            tenha alguma variação, será válido o valor de tabela atual da Instituição de Ensino Esta bolsa é
                            válida apenas para o Campus, Curso, Turno e modalidade informados neste Cupom. Se a inscrição ou
                            matrícula for realizada em outro Campus, Curso ou modalidade, será necessário um novo
                            Cadastro/Cupom
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
